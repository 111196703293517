import sismojs from '@/plugins/sismojs'
import { State } from '@/types'
import { BeachballEngine } from '@/utils'

const positionAsked = localStorage.getItem('positionAsked')
const positionAccepted = localStorage.getItem('positionAccepted')

const state: State = {
  fdsnClient: new sismojs.fdsn.Client('.'),
  events: [],
  focusEvent: null,
  inventory: {},
  loading: false,
  fmMode: false,
  fmFilter: null,
  beachballEngine: new BeachballEngine(50, 40, 1.5, `${location.href.split('#')[0]}static/wasm/beachball.wasm`),
  position: null,
  positionAsked: positionAsked != null ? positionAsked === 'true' : false,
  positionAccepted: positionAccepted != null ? positionAccepted === 'true' : false,
  stations: [],
  focusStation: null,
  documentHidden: false,
  version: null
}
export default state
