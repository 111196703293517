import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'leaflet/dist/leaflet.css'
import VueHighcharts from 'vue-highcharts'
import './registerServiceWorker'
import VueI18n from 'vue-i18n'
import messages from './messages'

Vue.use(VueHighcharts)
Vue.use(VueI18n)

Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages
})

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
